import Layout from "../components/layout";
import React from "react";
import SEO from "../components/seo";
import {graphql} from "gatsby"

function BlogPage({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <div className="section container p-3 bg-gray-300">
        <div>
          <div>
            <h1 className="text-3xl text-gray-900 m-0">
              {post.frontmatter.title}
            </h1>
            <h2 className="text-xl text-gray-700 m-0 mt-1">
              {post.frontmatter.description}
            </h2>
          </div>
        </div>
        <div>
          <div className="mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
            <p className="text-s m-0 text-gray-600">
              ~{post.timeToRead} minute read
            </p>
            <p className="text-s m-0 text-gray-600">
              posted on {post.frontmatter.date}
            </p>
          </div>
        </div>
      </div>


      <div className="mt-4 blog-post" dangerouslySetInnerHTML={{ __html: post.html }}>
      </div>

    </Layout>
  );
}

export default BlogPage;

export const query = graphql`
    query BlogPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            timeToRead
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
            }
        }
    }
`;
